<template>
  <div>
    <b-card
      id="customer-form-create"
      ref="customer-form-create"
      no-body
      class="custom-border-top-1px"
    >
      <div class="card-header">
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >
          THÔNG TIN CƠ BẢN
        </b-badge>
        <div class="d-flex flex-column">
          <b-button
            v-b-tooltip.hover="'Người liên lạc, điện thoại'"
            :variant="getStatusCheckDuplicateBtn()"
            size="sm"
            @click="handleCheckCustomerUnique"
          >
            <feather-icon icon="AlertCircleIcon" />
            Kiểm tra
          </b-button>
        </div>
      </div>
      <div class="card-body">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <!--            Tên công ty-->
            <dynamic-form
              :id="'filter-form-company_name'"
              :value="value.company_name"
              :type="'text'"
              placeholder="Tên công ty"
              :required="required.company_name ? 'required' : ''"
              :label="'Tên công ty'"
              @update-value="val => value.company_name = val"
            />

          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="3"
          >

            <!--            Người liên lạc-->
            <dynamic-form
              :id="'filter-form-host_name'"
              :value="value.host_name"
              :type="'text'"
              placeholder="Người liên lạc"
              :required="required.host_name ? 'required' : ''"
              :label="'Người liên lạc'"
              @update-value="val => value.host_name = val"
            />

          </b-col>
          <b-col
            cols="12"
            sm="6"
            lg="3"
          >

            <!--            Điện thoại-->
            <dynamic-form
              :id="'filter-form-host_phone1'"
              :value="value.host_phone1"
              :type="'text'"
              placeholder="Điện thoại"
              :required="required.host_phone1 ? 'required' : ''"
              :label="'Điện thoại'"
              @update-value="val => value.host_phone1 = val"
            />

          </b-col>

          <b-col
            cols="12"
            sm="6"
            lg="3"
          >

            <!--            Email-->
            <dynamic-form
              :id="'filter-form-email'"
              :value="value.email"
              :type="'text'"
              placeholder="Email"
              :required="required.email ? 'required' : ''"
              :label="'Email'"
              @update-value="val => value.email = val"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group>
              <label>Thông tin về diện tích <span class="text-danger">(*)</span></label>
              <b-form-textarea
                id="area_description"
                v-model="value.area_description"
                placeholder="Thông tin về diện tích"
                rows="2"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group>
              <label>Thông tin về khu vực <span class="text-danger">(*)</span></label>
              <b-form-textarea
                id="region_description"
                v-model="value.region_description"
                placeholder="Thông tin về khu vực"
                rows="2"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group>
              <label>Thông tin về ngân sách <span class="text-danger">(*)</span></label>
              <b-form-textarea
                id="budget_description"
                v-model="value.budget_description"
                placeholder="Thông tin về ngân sách"
                rows="2"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group>
              <label>Thông tin khác <span class="text-danger">(*)</span></label>
              <b-form-textarea
                id="more_description"
                v-model="value.more_description"
                placeholder="Thông tin khác"
                rows="2"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <small class="font-weight-bolder text-primary">Thông tin khác</small>
        <hr class="mt-0">

        <b-row>
          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <!--            Số lượng ô-tô xe máy-->
            <dynamic-form
              :id="'filter-form-vehicle_description'"
              :value="value.vehicle_description"
              :type="'text'"
              placeholder="Số lượng ô-tô xe máy"
              :label="'Số lượng ô-tô xe máy'"
              append-text="Chiếc"
              @update-value="val => value.vehicle_description = val"
            />

          </b-col>
          <b-col
            cols="12"
            sm="6"
            lg="3"
          >

            <b-form-group>
              <label>Thời gian cần chuyển vào <span class="text-danger">(*)</span></label>
              <flat-pickr
                id="move_in_at"
                v-model="value.move_in_at"
                class="form-control"
                :config="{ altFormat: 'd-m-Y', altInput: true, mode: 'single', allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: true }"
              />
            </b-form-group>

          </b-col>
          <b-col
            cols="12"
            sm="6"
            lg="3"
          >

            <!--            Mục đích công năng sử dụng-->
            <dynamic-form
              :id="'filter-form-purpose_description'"
              :value="value.purpose_description"
              :type="'text'"
              placeholder="Mục đích công năng sử dụng"
              :label="'Mục đích công năng sử dụng'"
              @update-value="val => value.purpose_description = val"
            />

          </b-col>
          <b-col
            cols="12"
            sm="6"
            lg="3"
          >

            <!--            Tòa nhà đã xem-->
            <dynamic-form
              :id="'filter-form-viewed_office'"
              :value="value.viewed_office"
              :type="'text'"
              placeholder="Tòa nhà đã xem"
              :label="'Tòa nhà đã xem'"
              @update-value="val => value.viewed_office = val"
            />

          </b-col>
          <b-col
            cols="12"
            sm="6"
            lg="3"
          >
            <!--            Ngoài giờ-->
            <dynamic-form
              :id="'filter-form-over_time_description'"
              :value="value.over_time_description"
              :type="'text'"
              placeholder="Ngoài giờ"
              :label="'Ngoài giờ'"
              @update-value="val => value.over_time_description = val"
            />

          </b-col>
          <b-col
            cols="12"
            sm="6"
            lg="3"
          >

            <!--            Thời hạn hợp đồng-->
            <dynamic-form
              :id="'filter-form-contract_time'"
              :value="value.contract_time"
              :type="'number'"
              placeholder="Thời hạn hợp đồng"
              :label="'Thời hạn hợp đồng'"
              append-text="Năm"
              @update-value="val => value.contract_time = val"
            />

          </b-col>
          <b-col
            cols="12"
            sm="6"
            lg="3"
          >

            <!--            Tòa nhà đang thuê-->
            <dynamic-form
              :id="'filter-form-current_office'"
              :value="value.current_office"
              :type="'text'"
              placeholder="Tòa nhà đang thuê"
              :label="'Tòa nhà đang thuê'"
              @update-value="val => value.current_office = val"
            />

          </b-col>
          <b-col
            cols="12"
            sm="6"
            lg="3"
          >

            <!--            Địa chỉ website và ngành nghề-->
            <dynamic-form
              :id="'filter-form-more_info'"
              :value="value.more_info"
              :type="'text'"
              placeholder="Địa chỉ website và ngành nghề"
              :label="'Địa chỉ website và ngành nghề'"
              @update-value="val => value.more_info = val"
            />

          </b-col>
        </b-row>

        <small class="font-weight-bolder text-primary">Tiêu chí lọc, phân loại khách hàng (HCM)</small>
        <hr class="mt-0">

        <b-row>
          <b-col
            cols="12"
            lg="6"
          >

            <!--              quận huyện-->
            <dynamic-form
              :id="'filter-form-quanhuyen'"
              :value="value.district_ids"
              :type="'multi-select'"
              :label="'Quận/Huyện'"
              value-field="id"
              text-field="name"
              :options="districts"
              :select-default="false"
              @update-value="val => value.district_ids = val"
            />

            <!--              Phường/Xã-->
            <dynamic-form
              :id="'customer-form-phuongxa'"
              :value="value.ward_id"
              :type="'select'"
              :label="'Phường/Xã'"
              value-field="id"
              text-field="name"
              :options="wardsByDistricts"
              :select-default="false"
              :clearable="true"
              @update-value="val => value.ward_id = val"
            />

            <!--              Đường-->
            <dynamic-form
              :id="'customer-form-duong'"
              :value="value.street_ids"
              :type="'multi-select'"
              :label="'Đường'"
              value-field="id"
              text-field="name"
              :options="streetsByDistricts"
              :select-default="false"
              @update-value="val => value.street_ids = val"
            />

          </b-col>
          <b-col
            cols="12"
            lg="6"
          >

            <div class="d-flex mb-1 flex-column">
              <label class="minw-100px">Giá (USD/m<sup>2</sup>) <span class="text-danger">(*)</span></label>
              <div class="grid-2-columns">
                <b-input-group append="≤">
                  <b-form-input
                    v-model="value.min_price"
                    placeholder="Tối thiểu"
                  />
                </b-input-group>
                <b-input-group prepend="≤">
                  <b-form-input
                    v-model="value.max_price"
                    placeholder="Tối đa"
                  />
                </b-input-group>
              </div>
            </div>

            <div class="d-flex mb-1 flex-column">
              <label class="minw-100px">Diện tích (m2) <span class="text-danger">(*)</span></label>
              <div class="grid-2-columns">
                <b-input-group append="≤">
                  <b-form-input
                    v-model="value.acreage"
                    placeholder="Tối thiểu"
                  />
                </b-input-group>
                <b-input-group prepend="≤">
                  <b-form-input
                    v-model="value.max_area"
                    placeholder="Tối đa"
                  />
                </b-input-group>
              </div>
            </div>

            <b-form-group>
              <label>Thời gian nhận khách <span class="text-danger">(*)</span></label>
              <flat-pickr
                id="move_in_at"
                v-model="value.reception_at"
                class="form-control"
                :config="{ altFormat: 'd-m-Y', altInput: true, mode: 'single', allowInvalidPreload: true, dateFormat: 'Y-m-d', allowInput: true }"
              />
            </b-form-group>

          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge, BCard, BCol, BRow, BButton, VBTooltip, BInputGroup, BFormInput, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import {
  ref, reactive, computed,
} from '@vue/composition-api'
import DynamicForm from '@core/dynamic-form/DynamicForm.vue'
import useCustomerOfficeCreate from '@/views/customer-office/create/useCustomerOfficeCreate'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BBadge,
    BCard,
    BCol,
    BRow,
    DynamicForm,
    BButton,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    flatPickr,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: Object,
      require: true,
      default: () => {},
    },
  },
  computed: {
    houseStyleList() {
      const style = [
        this.value.terrace ? 'terrace' : '',
        this.value.basement ? 'basement' : '',
        this.value.badger ? 'badger' : '',
        this.value.elevator ? 'elevator' : '',
      ]
      return style || []
    },
    specialList() {
      const style = [
        this.value.is_hot ? 'is_hot' : '',
        this.value.has_corner ? 'has_corner' : '',
        this.value.show_in_web ? 'show_in_web' : '',
      ]
      return style || []
    },
  },
  watch: {
    value: {
      deep: true,
      handler(n) {
        if (!n) return
        this.formErrorText = undefined
        Object.keys(this.required).forEach(key => {
          if (!n[key] && this.required[key]) {
            this.formErrorText = this.formErrorText || []
            this.formErrorText.push(this.required[key])
          }
        })
        this.$emit('update-form-status', this.formErrorText)
      },
    },
  },
  methods: {
    getStatusCheckDuplicateBtn() {
      switch (this.customerUniqueStatus) {
        case undefined:
        case null:
          return 'success'
        case '':
          return 'primary'
        default:
          return 'warning'
      }
    },
    handleCheckCustomerUnique() {
      if (!this.value.host_name || !this.value.host_phone1) {
        this.$toastr('Vui lòng nhập tên và số điện thoại khách hàng', 'danger')
        return
      }
      this.$call(this.checkCustomerUnique(this.customer, res => {
        const error = res.data.message || ''
        if (error) {
          this.duplicateError = error
          this.$toastr(error, 'warning')
        } else this.$showNoti('Khách hàng là duy nhất')
        this.customerUniqueStatus = error || ''
        this.$emit('handle-duplicate-error', error)
      }))
    },
    updateByCheckboxGroup(options, checkedList) {
      if (!options.length) return
      options.forEach(item => {
        this.value[item.value] = checkedList.includes(item.value)
      })
    },
  },
  setup(props) {
    const {
      districts,
      wardsByDistricts,
      streetsByDistricts,
      checkCustomerUnique,
    } = useCustomerOfficeCreate()

    const required = reactive({
      company_name: 'Tên công ty là bắt buộc',
      // max_area: 'Diện tích tối đa là bắt buộc',
      // floor_number: computed(() => (props.value.type !== 'san-thuong' ? 'Số lầu là bắt buộc' : '')),
      // max_floor_number: computed(() => (props.value.type !== 'san-thuong' ? 'Số lầu tối đa là bắt buộc' : '')),
      // room_from: computed(() => (props.value.type === 'khach-san' ? 'Số phòng tối thiểu là bắt buộc' : '')),
      // room_to: computed(() => (props.value.type === 'khach-san' ? 'Số phòng tối đa là bắt buộc' : '')),
      // input_price: 'Giá là bắt buộc',
      // type_of_payment: 'Kiểu thanh toán là bắt buộc',
      host_name: 'Tên người liên lạc là bắt buộc',
      host_phone1: 'SĐT người liên lạc là bắt buộc',
      email: 'Email người liên lạc là bắt buộc',
      area_description: 'Thông tin diện tích là bắt buộc',
      region_description: 'Thông tin khu vực là bắt buộc',
      budget_description: 'Thông tin ngân sách là bắt buộc',
      more_description: 'Thông tin khác là bắt buộc',
      move_in_at: 'Thời gian cần chuyển vào là bắt buộc',
      reception_at: 'Thời gian nhận khách là bắt buộc',
      min_price: 'Giá tối thiểu là bắt buộc',
      max_price: 'Giá tối đa là bắt buộc',
      max_area: 'Diện tích tối đa là bắt buộc',
      acreage: 'Diện tích tối thiểu là bắt buộc',
    })

    const tooltipPriceDisplayCustomer = ['[Thanh toán là m2]',
      'Giá tổng = Giá tối đa x Diện tích',
      '[Thanh toán là tổng/tháng]',
      'Giá tổng = Giá tối đa nhập',
      '[Diện tích]',
      'Diện tích = Ngang tối thiểu x Dài tối thiểu x (Lầu tối thiểu + 1)',
    ].join('<br/>')

    const customer = reactive({
      host_name: computed(() => props.value.host_name),
      host_phone1: computed(() => props.value.host_phone1),
    })
    const customerUniqueStatus = ref(undefined) // [undefined, '', error]
    const duplicateError = ref('')

    return {
      duplicateError,
      required,
      customer,
      customerUniqueStatus,
      districts,
      wardsByDistricts,
      streetsByDistricts,
      tooltipPriceDisplayCustomer,
      //
      checkCustomerUnique,
    }
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
